import React from 'react'

import Carousel from 'react-elastic-carousel'

import './index.css'
import SkillItem from './skillItem'

const categories = [
    { 
        id: 1,
        name: "MOBILE",
        items: ["Swift", "SwiftUI", "CocoaPods, Carthage, SPM", "XCTest", "Flutter"]
    },
    { 
        id: 2,
        name: "FRONTEND",
        items: ["HTML", "CSS", "Javasript", "ReactJS", "VueJS", "Bootstrap"]
    },
    { 
        id: 3,
        name: "BACKEND",
        items: ["NodeJS", "Express", "PHP", "Laravel", "MySQL", "PostgreSQL"]
    }
]

const breakPoints = [
    { width: 1, itemsToShow: 1, itemPadding: [0, 16] },
    { width: 700, itemsToShow: 2, itemPadding: [0, 16] },
    { width: 980, itemsToShow: 3, pagination: false, itemPadding: [0, 16] }
]

let activeCategory = 1

const Skills = () => (
    <section className="skills">
        <Carousel breakPoints={breakPoints} showArrows={false} initialActiveIndex={2}>
            { categories.map(category => {
                return <SkillItem 
                    key={ category.id }
                    categoryName={ category.name }
                    items={ category.items }
                    active={ category.id === activeCategory }
                />
            }) }
        </Carousel>
    </section>
)

export default Skills