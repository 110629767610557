// Game stream
// Icon
import gameStreamIcon from '../assets/img/projects/1/icon.webp'
// Screenshots
import gameStreamProfile from '../assets/img/projects/1/screenshots/profile.webp'
import gameStreamHome from '../assets/img/projects/1/screenshots/home.webp'
import gameStreamGames from '../assets/img/projects/1/screenshots/games.webp'
import gameStreamFav from '../assets/img/projects/1/screenshots/favorites.webp'

// UIC Icon
import UICIcon from '../assets/img/projects/2/icon.webp'

// Print Book
// Screenshots
import printBookHome from '../assets/img/projects/2/screenshots/home.webp'
import printBookDetails from '../assets/img/projects/2/screenshots/book-details.webp'

// AirTag Concept
// Screenshots
import airTagConceptSplash from '../assets/img/projects/3/screenshots/splash.webp'
import airTagConceptHome from '../assets/img/projects/3/screenshots/home.webp'

// Alienforce Task Manager
// Screenshots
import alienforceWelcome from '../assets/img/projects/4/screenshots/welcome-view.webp'
import alienforceHome from '../assets/img/projects/4/screenshots/home.webp'
import alienforceMenu from '../assets/img/projects/4/screenshots/context-menu.webp'

const projects = [
    {
        id: 1,
        name: "Game Stream",
        icon: gameStreamIcon,
        screenshots: [
            gameStreamHome,
            gameStreamProfile,
            gameStreamGames,
            gameStreamFav,
        ],
        platform: "iOS",
        tools: ["SwiftUI", "Kingfisher"],
        description: "This is an app where users can read updates and news about videogames they like.",
        link: "https://github.com/unferna/GameStream",
        isPersonalProject: false
    },
    {
        id: 2,
        name: "Print Book",
        icon: UICIcon,
        screenshots: [
            printBookHome,
            printBookDetails,
        ],
        platform: "iOS",
        tools: ["SwiftUI", "Kingfisher"],
        description: "This app takes part of the User Interface Challenge (UIC) proposed by Apple Dev's Lab.",
        link: "https://github.com/unferna/PrintBook-UIC",
        isPersonalProject: false
    },
    {
        id: 3,
        name: "AirTag Concept",
        icon: UICIcon,
        screenshots: [
            airTagConceptSplash,
            airTagConceptHome,
        ],
        platform: "iOS",
        tools: ["SwiftUI"],
        description: "This app takes part of the User Interface Challenge (UIC) proposed by Apple Dev's Lab.",
        link: "https://github.com/unferna/AirTagConcept-UIC",
        isPersonalProject: false
    },
    {
        id: 4,
        name: "Alienforce Task Manager",
        icon: UICIcon,
        screenshots: [
            alienforceWelcome,
            alienforceHome,
            alienforceMenu
        ],
        platform: "iOS",
        tools: ["SwiftUI"],
        description: "This app takes part of the User Interface Challenge (UIC) proposed by Apple Dev's Lab.",
        link: "https://github.com/unferna/AlienforceTaskManager-UIC",
        isPersonalProject: false
    }
]

export default projects