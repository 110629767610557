import React from 'react'
import './education.css'

import EducationItem from './EducationItem'
const certsLink = `https://drive.google.com/drive/folders/1dLvFvUBTWhAT-zea3MuA0wtcWZ9jiny3?usp=sharing`
const educationList = [
    {
        id: 1,
        name: [ "English as a Second Language" ],
        start: "2019",
        end: "2020",
        where: "Universidad del Atlántico"
    },
    {
        id: 2,
        name: "Tecnólogo en Desarrollo de Software",
        start: "2016",
        end: "2019",
        where: "Sena"
    },
    {
        id: 3,
        name: "Técnico en Programación de Software",
        start: "2014",
        end: "2016",
        where: "Sena"
    }
]

const Education = () => {
    return (
        <section className="education" id="education">
            <h1 className="main-heading">Education</h1>

            <div className="education-list">
                {
                    educationList.map(item => {
                        return <EducationItem 
                            key={ item.id }
                            { ...item }
                        />
                    })
                }
                
            </div>

            <div className="section-landing-wrapper">
                <p className="section-landing">Check my <a href={certsLink} target="_blank" rel="noopener noreferrer"><span className="highlighted-italic-text">Certificates</span></a></p>
            </div>
        </section>
    ) 
}

export default Education