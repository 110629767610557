import React from 'react'
import './footer.css'

import githubImage from '../../assets/img/social-icons/github.png'
import linkedinImage from '../../assets/img/social-icons/linkedin.png'
import appleImage from '../../assets/img/social-icons/apple.png'
import androidImage from '../../assets/img/social-icons/android.png'
import instagramImage from '../../assets/img/social-icons/instagram.png'

const mail = "hi@fernandoflorez.dev"
const resumeLink = "https://drive.google.com/file/d/1AJMvDZe-15c4mfxMvaQSoNLSZztbZM5I/view?usp=share_link"

const links = [
    {
        id: 1,
        name: "Github",
        alt: "Github social icon",
        icon: githubImage,
        link: "https://github.com/unferna"
    },
    {
        id: 2,
        name: "LinkedIn",
        alt: "LinkedIn social icon",
        icon: linkedinImage,
        link: "https://www.linkedin.com/in/fernando-florez/"
    },
    {
        id: 3,
        name: "Apple",
        alt: "Apple social icon",
        icon: appleImage,
        link: "#"
    },
    {
        id: 4,
        name: "Android",
        alt: "Android social icon",
        icon: androidImage,
        link: "#"
    },
    {
        id: 5,
        name: "Instagram",
        alt: "Instagram social icon",
        icon: instagramImage,
        link: "https://instagram.com/unferna"
    },
]

const ContactFooter = () => (
    <footer>
        <h1 className="main-heading">Get in touch!</h1>
        <p className="reach-links">How to reach me? <a href={`mailto: ${mail}`}><span className="highlighted-italic-text">{mail}</span></a><br />Take a look to my <a href={resumeLink} target="_blank" rel="noopener noreferrer"><span className="highlighted-italic-text">resume</span></a></p>

        <div className="social-links">
            <nav>
                { links.map(linkItem => {
                    let finalLink = <a href={linkItem.link} key={linkItem.id} target="_blank" rel="noopener noreferrer"><img src={linkItem.icon} alt={linkItem.alt} /></a>
                    
                    if( linkItem.link === "#" ) {
                        finalLink = <a href={linkItem.link} key={linkItem.id}><img src={linkItem.icon} alt={linkItem.alt} /></a>
                    }
                    
                    return finalLink
                }) }
            </nav>

            <div className="spacer"></div>
            <p className="signature">Made with ❤️ <span className="signature-name">by Fernando</span></p>
        </div>
    </footer>
)

export default ContactFooter