import React from 'react'

import './index.css'

const SkillItem = ({ categoryName, items, active }) => {
    let itemNames = items

    if( items.length >= 7 ) {
        itemNames = items.slice(0, 6)
        itemNames.push("...")
    }

    return (
        <div className={`skill-section${ active ? " active": "" }`}>
            <ul className="skill-list">
                {
                    itemNames.map(
                        item => <li className="skill-item" key={item}>{ item }</li>
                    )
                }
            </ul>

            <h2 className="skill-category-name">{categoryName}</h2>
        </div>
    )
}


export default SkillItem