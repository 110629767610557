import React from 'react'

const getElementY = query => (
    window.pageYOffset + document.querySelector(query).getBoundingClientRect().top
)

const easing = time => { 
    return time < .5 ? 4 * time * time * time : (time - 1) * (2 * time - 2) * (2 * time - 2) + 1
}

/**
 * Credits: Daniel Sawka
 * https://stackoverflow.com/a/39494245/11263377
 * 
*/ 
const doScrollingTo = (element, duration) => {
    const startingY = window.pageYOffset
    const elementY = getElementY(element)
    const headerHeight = 60

    const targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight -  window.innerHeight : elementY
    const diff = targetY - startingY - headerHeight
    
    let start

    if( !diff ) return

    window.requestAnimationFrame(function step(timestamp) {
        if( !start ) start = timestamp
        
        let time = timestamp - start
        let percent = Math.min(time / duration, 1)

        percent = easing(percent)

        window.scrollTo(0, startingY + diff * percent)

        if(time < duration) {
            window.requestAnimationFrame(step)
        }
    })
}

const handleElementClick = element => {
    element.preventDefault()
    const target = element.target.getAttribute("href")

    doScrollingTo(target, 600)
}

const handleMobileElementClick = element => {
    element.preventDefault()
    const target = element.target.getAttribute("href")

    toggleMobileHeader()
    doScrollingTo(target, 600)
}

const toggleMobileHeader = () => {
    const burgerButton = document.querySelector(".burger-button")
    const mobileHeader = document.querySelector(".mobile-header")
    const mobileHeaderOptions = document.querySelector(".mobile-header-options")
    const body = document.body

    burgerButton.classList.toggle("active")
    const hadClass = mobileHeader.classList.toggle("active")
    mobileHeader.setAttribute("aria-hidden", !hadClass)

    mobileHeaderOptions.classList.toggle("hidden")
    body.classList.toggle("nonscroll")
}

const Header = () => (
    <div>
        <header>
            <nav>
                <li><a href="#about-section" className="header-item active" onClick={ e => handleElementClick(e) }>About me</a></li>
                <li><a href="#experience" className="header-item" onClick={ e => handleElementClick(e) }>Experience</a></li>
                <li><a href="#education" className="header-item" onClick={ e => handleElementClick(e) }>Education</a></li>
                <li><a href="#portfolio" className="header-item" onClick={ e => handleElementClick(e) }>Portfolio</a></li>
                {/* <li><a href="#" className="header-item">Español?</a></li> */}
            </nav>
        </header>

        <div className="mobile-header" aria-hidden={true}>
            <div className="blur"></div>

            <button className="burger-button" onClick={toggleMobileHeader}>
                <span className="burger-line"></span>
                <span className="burger-line"></span>
                <span className="burger-line"></span>
            </button>

            <ul className="mobile-header-options hidden">
                <li><a href="#about-section" className="header-item active" onClick={ e => handleMobileElementClick(e) }>About me</a></li>
                <li><a href="#experience" className="header-item" onClick={ e => handleMobileElementClick(e) }>Experience</a></li>
                <li><a href="#education" className="header-item" onClick={ e => handleMobileElementClick(e) }>Education</a></li>
                <li><a href="#portfolio" className="header-item" onClick={ e => handleMobileElementClick(e) }>Portfolio</a></li>
            </ul>
        </div>
    </div>
    
)

export default Header