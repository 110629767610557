import React from 'react'
import './experience.css'

import ExperienceItem from './ExperienceItem'

const linkedIn = "https://www.linkedin.com/in/fernando-florez/"

const experiences = [
    {
        id: 2,
        start: "2020",
        end: "2021",
        charge: "iOS Developer",
        companyName: "Plankk",
        jobDescription: `Fitness is a big world that implies big opportunities and this company knows it. I was in charge of the development of some iOS projects. Some of them have been doing from scratch and others have not. Communication is the key, our products are multiplatform. Often frontend, backend, and mobile teams give opinions and ideas to make great products.`
    },
    {
        id: 3,
        start: "2021",
        end: "2022",
        charge: "Middle iOS Developer",
        companyName: "Matter Supply Co",
        jobDescription: `Here I worked on a product for Fannie Mae ®. I developed new features along with a great UI/UX/Product team. We proposed our considerations to make the product better.`
    },
    {
        id: 4,
        start: "2022",
        end: "2023",
        charge: "Middle iOS Developer",
        companyName: "Empowerment Labs",
        jobDescription: `I develop features and support the existing ones to create the best finance experience to track people's financial health. Along with UI/UX/Product team, we make sure that our decisions and considerations are reflected in a good product.`
    },
    {
        id: 5,
        start: "2023",
        end: "present",
        charge: "Middle iOS Developer (Self-Employed)",
        companyName: "Freelance",
        jobDescription: `Available to work on the developing of frontend apps focused on mobile side with Swift, SwiftUI or Flutter.`
    },
]

const reversedExperience = experiences.reverse().slice(0, 5)

const Experience = () => (
    <section className="experience" id="experience">
        <h1 className="main-heading">Experience</h1>

        <div className="experiences-list">
            { reversedExperience.map(item => <ExperienceItem key={item.id} { ...item } />) }
        </div>

        <div className="section-landing-wrapper">
            <p className="section-landing">See more on <a href={linkedIn} target="_blank" rel="noopener noreferrer"><span className="highlighted-italic-text">LinkedIn</span></a></p>
        </div>
    </section>
)

export default Experience