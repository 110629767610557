import React, { useState, useEffect } from 'react'

import './assets/css/general.css'

import About from './modules/About'
import ContactFooter from './modules/ContactFooter'
import Education from './modules/Education'
import Experience from './modules/Experience'
import Portfolio from './modules/Portfolio'
import PortofolioModalInfo from './modules/Portfolio/ModalInfo'

import projects from './static/projects'

const handleResize = () => {
  const app = document.querySelector(".App")
  const appHeight = app.offsetHeight

  document.querySelector(".main-background").style.height = `${appHeight}px`
}

const handleBasicMobileScroll = () => {
  window.addEventListener("scroll", () => {
    const currentY = window.scrollY

    document.querySelector(".mobile-header").classList.toggle("offset-y-changed", currentY > 0)
  }, false)
}

function App() {
  const [selectedProject, didSelectProject] = useState(undefined)

  const handleProjectSelection = index => {
    const modalInfo = document.querySelector(".portfolio-modal-overlay")
    
    const shown = modalInfo.classList.toggle("hidden-modal")
    modalInfo.setAttribute("aria-hidden", shown)
    
    const project = projects.find(item => item.id === index) ?? undefined
    didSelectProject(project)

    if(project !== undefined) {
      document.body.classList.toggle("nonscroll")
    }
  }

  const didTapCloseModal = () => {
    didSelectProject(undefined)
    document.body.classList.toggle("nonscroll")
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    window.addEventListener('load', handleResize)

    handleBasicMobileScroll()
  })

  return (
    <div className="App">
        <div className="main-background">
          <div className="blur"></div>
        </div>
        
        <About />
        <Experience />
        <Education />
        <Portfolio projects={projects} didProjectSelected={ handleProjectSelection } />
        <ContactFooter />
        <PortofolioModalInfo project={selectedProject} didTapClose={ didTapCloseModal } />
    </div>
  );
}

export default App;
