import React from 'react'

const ExperienceItem = ({ start, end, companyName, charge, jobDescription }) => {
    return (
        <div className="experience-item">
            <div className="date-interval"><p>{`${ start } ... ${ end }`}</p></div>
            
            <div className="experience-info">
                <h2 className="charge">{ charge }</h2>
                <h4 className="company-name">{ companyName }</h4>

                <p className="job-description">{ jobDescription }</p>
            </div>
        </div>
    )
}

export default ExperienceItem