import React, { useState } from 'react'
import PortfolioItem from './PortfolioItem'
import Carousel from 'react-elastic-carousel'

import './portfolio.css'

const breakPoints = [
    { width: 1, itemsToShow: 1, itemPadding: [0, 16] },
    { width: 700, itemsToShow: 2, itemPadding: [0, 16] },
    { width: 980, itemsToShow: 3, itemPadding: [0, 16] }
]

const Portfolio = ({ projects, didProjectSelected }) => {
    const [projectTypes] = useState([
        { label: "Study", value: 0, disabled: false },
        { label: "Personal", value: 1, disabled: true }
    ])

    const [projectsToShow, changeProjectsToShow] = useState(projects.filter(project => !project.isPersonalProject))

    const handleFilter = event => {
        const caughtValue = parseInt(event.target.value) !== 0
        changeProjectsToShow( projects.filter(project => project.isPersonalProject === caughtValue) )
    }

    return (
        <section className="portfolio" id="portfolio">
            <div className="portfolio-heading">
                <h1 className="main-heading" >Portfolio</h1>

                <select name="cboProjectType" id="cboProjectType" onChange={ handleFilter }>
                    { projectTypes.map(type => <option value={type.value} key={type.value} disabled={type.disabled}>{ type.label }</option>) }
                </select>
            </div>

            <Carousel breakPoints={breakPoints} showArrows={false}>
                { projectsToShow.map(project => (
                    <PortfolioItem
                        project={ project }
                        key={ project.id }
                        didProjectSelected={didProjectSelected}
                    />
                )) }
            </Carousel>
        </section>
    )
}

export default Portfolio    
