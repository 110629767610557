import React from 'react'

import './index.css'
import profilePicturePath from '../../assets/img/profilePicture.webp'

import Header from './header'
import Skills from './skills'

const About = () => (
    <div>
        <Header />

        <div className="about-section" id="about-section">
            {/* Profile Picture */}
            <div className="profile-picture-container">
                <div className="blurred-background"></div>
                <img src={ profilePicturePath } alt="Profile" className="main-picture" />
            </div>
        </div>

        <p className="about-description">I’m <span className="highlighted-italic-text">Fernando Florez,</span> a Fullstack Developer with experience in many tech niches.<br />These are some of my <span className="highlighted-italic-text">Skills...</span></p>

        <Skills />
    </div>
)

export default About