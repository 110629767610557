import React, { useEffect } from 'react'

import './portfolio-modal-info.css'

const print = text => console.log(text)

/**
 * Reference:
 * https://www.cssscript.com/draggable-touch-slider-carousel/
 */
const handleCarousel = async () => {
    const slider = document.querySelector(".modal-main-carousel")
    const sliderItemsContainer = document.querySelector(".items")

    const images = document.querySelectorAll(".project-screenshot")
    const totalImages = images.length
    const imageWidth = images[0].getBoundingClientRect().width
    const spacer = 8
    
    const totalSliderItemsContainerWidth = imageWidth * totalImages + spacer * (totalImages - 1)
    sliderItemsContainer.style.width = totalSliderItemsContainerWidth + "px"

    const sliderSidePadding = 16 // 16 -> Padding
    const totalSliderWidth = slider.offsetWidth - sliderSidePadding * 2
    const imagesThatCanFit = Math.floor( totalSliderWidth / (imageWidth + spacer) )

    const slide = (items, maxOffsetLeft) => {
        let posX1 = 0
        let posX2 = 0

        let lastSavedOffset = 0
    
        const dragAction = event => {
            event = event || window.event

            if(event.type === "touchmove") {
                posX2 = posX1 - event.touches[0].clientX
                posX1 = event.touches[0].clientX
            
            } else {
                posX2 = posX1 - event.clientX
                posX1 = event.clientX
            }

            lastSavedOffset = items.offsetLeft - posX2
            
            const lastImageRectRight = images[totalImages - 1].getBoundingClientRect().right
            
            // Scrolling right
            if(posX2 > 0) {
                print("lastImageRectRight:" + lastImageRectRight)
                print(`slider.offsetWidth: ${ slider.offsetWidth }`)
                print("Last saved offset:" + lastSavedOffset)
                print(`maxOffsetLeft: ${ maxOffsetLeft }`)
                print("----------------------------------------------")
                if(lastImageRectRight >= slider.offsetWidth - sliderSidePadding && lastSavedOffset > maxOffsetLeft) {
                    items.style.left = `${ lastSavedOffset }px`
                }

            // Scrolling left   
            } else if(posX2 < 0) {
                if(lastSavedOffset <= 0) {
                    items.style.left = `${ lastSavedOffset }px`
                }
            }
        }
        
        const dragStart = event => {
            event = event || window.event
            event.preventDefault()
            
            if(event.type === "touchstart") {
                posX1 = event.touches[0].clientX

            } else {
                posX1 = event.clientX
                document.onmouseup = dragEnd
                document.onmousemove = dragAction
            }
        }
        
        const dragEnd = () => {
            const lastImageRectRight = images[totalImages - 1].getBoundingClientRect().right

            // Scrolling Right
            if(posX2 > 0) {
                if(lastImageRectRight < slider.offsetWidth - sliderSidePadding) {
                    items.style.left = `${ maxOffsetLeft }px`
                }

            // Scrolling left   
            } else if(posX2 < 0) {
                if(lastSavedOffset > 0) {
                    items.style.left = `0px`
                }
            }

            document.onmouseup = null
            document.onmousemove = null
        }

        items.onmousedown = null
        items.removeEventListener('touchstart', dragStart)
        items.removeEventListener('touchsend', dragEnd)
        items.removeEventListener('touchmove', dragAction)
        
        // Reset events
        items.onmousedown = dragStart

        items.addEventListener('touchstart', dragStart)
        items.addEventListener('touchend', dragEnd)
        items.addEventListener('touchmove', dragAction)
    }

    if(totalImages > imagesThatCanFit) {
        setTimeout(() => {
            const maxOffsetLeft = slider.offsetWidth - sliderSidePadding - images[totalImages - 1].getBoundingClientRect().right
            
            slide(sliderItemsContainer, maxOffsetLeft)
        }, 8 * 100)
    }
}

const PortofolioModalInfo = ({ project, didTapClose }) => {
    useEffect(() => {
        if(project !== undefined) {
            handleCarousel()
        }
    })

    if(project === undefined) {
        return (<div className="portfolio-modal-overlay hidden-modal" aria-hidden={true}></div>)
    }

    return (
        <div className="portfolio-modal-overlay">
            <div className="portfolio-modal" aria-hidden={ project === undefined }>
                <button className="close-button" onClick={ didTapClose }>Close</button>

                <div className="project-heading">
                    <img src={ project.icon } alt="Project icon" className="project-icon" />
                    <h1 className="project-name">{ project.name }</h1>
                </div>

                <div className="modal-main-carousel">
                    <div className="wrapper">
                        <div className="items">
                            { project.screenshots.map((photo, idx) => {
                                return (
                                    <img src={photo} alt="Project Screenshot" className="project-screenshot" key={idx} />
                                )
                            }) }
                        </div>
                    </div>
                </div>

                <ul className="project-info">
                    <li><strong>Name: </strong>{ project.name }</li>
                    <li><strong>Platform: </strong>{ project.platform }</li>
                    <li><strong>Tools: </strong>{ project.tools.join(", ") }</li>
                    <li><strong>Description: </strong>{ project.description }</li>
                    { project.link && <li><strong>Link: </strong><a href={ project.link } className="project-link"  target="_blank" rel="noopener noreferrer">{ project.link }</a></li> }
                </ul>
            </div>
        </div>
    )
}

export default PortofolioModalInfo