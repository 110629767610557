import React from 'react'

const EducationItem = ({ name, start, end, where }) => {
    return (
        <div className="education-item">
            <h3 className="education-title">{ name }</h3>

            <div className="education-info">
                <p>{ `${start} ... ${end}` }</p>
                <p>{ where }</p>
            </div>
        </div>
    )
}

export default EducationItem