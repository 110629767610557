import React from 'react'

import appleIcon from '../../../assets/img/platform-and-tools/apple-tool.png'
import androidIcon from '../../../assets/img/platform-and-tools/android-tool.png'
import reactIcon from '../../../assets/img/platform-and-tools/reactjs-tool.png'
import vueIcon from '../../../assets/img/platform-and-tools/vuejs-tool.png'

const platformIconDictionary = {
    "ios": appleIcon,
    "android": androidIcon,
    "react": reactIcon,
    "vue": vueIcon
}

const screenshotContainer = (image, platform) => {
    const ableToShow = image !== undefined
    if( ableToShow ) {
        return (
            <div className="portfolio-item-screenshot-container">
                <img src={image} alt="Project Screenshot" className="screenshot" />
                <img src={platform} alt="Project Platform" className="platform" />
            </div>
        )
    }

    return <div className="portfolio-item-screenshot-container"></div>
}

const PortfolioItem = ({ project, didProjectSelected }) => (
    <div className="portfolio-item" onClick={() => { didProjectSelected(project.id) }}>
        <div className="portfolio-item-heading">
            <img src={ project.icon } alt="Project" />
            <h2>{project.name}</h2>
        </div>

        { screenshotContainer(project.screenshots[0], platformIconDictionary[ project.platform.toLowerCase() ]) }
    </div>
)

export default PortfolioItem